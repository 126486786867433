<template>
  <el-card>
    <common-user-center-title :title="'用户信息'"></common-user-center-title>
    <el-form>
      <el-form :model="userInfoForm" :rules="userInfoFormRules"
               ref="userInfoFormRef" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="昵称" prop="name">
              <el-input v-model="userInfoForm.name"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="userInfoForm.email"></el-input>
            </el-form-item>
            <el-form-item label="头像" prop="image">
              <el-upload
                class="avatar-uploader"
                accept=".gif,.jpg,.jpeg,.png,.GIF,.JPG,.PNG"
                action="/api/upload"
                :show-file-list="false"
                name="file"
                :on-error="addUserAvatarError"
                :http-request="addUserAvatarFileUpload"
              >
                <img v-if="userInfoForm.image" :src="userInfoForm.image" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="userInfoForm.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
                <el-radio :label="0">保密</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="电话" prop="phone">
              <el-input :disabled="true" v-model="userInfoForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="积分" prop="point">
              <el-input :disabled="true" v-model="userInfoForm.point"></el-input>
            </el-form-item>
            <el-form-item label="余额" prop="money">
              <el-input :disabled="true" v-model="userInfoForm.money"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="userInfoSubmit">修改信息</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-form>
  </el-card>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import {userInfo as getUserInfoUrl,fileUploadUrl,modifyUserInfoUrl} from '@/api/index'
export default {
  name: 'UserInfo',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      userInfoForm:{
        name:'',
        phone:'',
        image:'',
        sex:'',
        email:'',
        money:'',
        point:'',
      },
      userInfoFormRules:{},
    }
  },
  created () {
    this.getUserInfo()
  },
  methods:{
    async getUserInfo(){
      const{data:res} = await this.$http.get(getUserInfoUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      window.sessionStorage.setItem('userInfo', JSON.stringify(res.data))
      this.userInfoForm = res.data
    },
    // 添加用户上传头像失败事件
    addUserAvatarError(err) {
      this.$message.error('头像上传失败' + err)
    },
    // 通过自定义方法实现文件上传
    async addUserAvatarFileUpload (params) {
      console.log(params)
      // 如果不是图片格式，不进行上传，并且给错误提示
      if (params.file.type !== 'image/gif' && params.file.type !== 'image/jpg' &&
        params.file.type !== 'image/jpeg' && params.file.type !== 'image/png' &&
        params.file.type !== 'image/GIF' && params.file.type !== 'image/JPG') {
        return this.$message.error('文件上传失败，请上传正确格式的图片')
      }
      let form = new FormData()
      // 组织文件参数
      form.append('file', params.file)
      const { data: res } = await this.$http.post(fileUploadUrl, form)
      console.log(res)
      if (res.code !== 200) {
        this.$message.error('文件上传失败' + res.msg)
        return false
      }
      this.userInfoForm.image = res.data
    },
    // 提交用户信息
    async userInfoSubmit(){
      if(this.userInfoForm.email === ''){
        // 后台会判断邮箱格式，如果email字段传空的话，需要删除这个字段
        delete this.userInfoForm.email
      }
      const{data:res} = await this.$http.post(modifyUserInfoUrl,this.userInfoForm)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('修改个人信息成功')
      this.getUserInfo()
      console.log(res)
    },
  },

}
</script>

<style scoped>
  .avatar-uploader{
    width: 100px;
    height: 100px;
    border: 1px #dddddd dashed;
  }
  .el-form-item img{
   width: 100px;
    height: 100px;
  }
</style>
